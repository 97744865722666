




































































.uppercase input {
	text-transform: uppercase;
}
