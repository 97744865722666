


























































































.role-row {
	box-shadow: 0 2px 2px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 0px 2px 0 rgba(0,0,0,.12);
	z-index: auto;
}
.pre-settings-panel {
	width: 40px;
	box-shadow: inset -11px 0px 3px -10px #dbdbdb;
}
.post-settings-panel {
	width: 10px;
	box-shadow: inset 11px 0px 3px -10px #dbdbdb;
}
.whole-settings-panel .settings-panel {
	box-shadow: 0px 1px 1px 0px rgba(0,0,0,.12),0px 2px 2px -1px rgba(0,0,0,.12),inset 0 10px 5px -10px #dbdbdb;
	flex: 1 1;
}
.whole-settings-panel {
	display: flex;
	padding-bottom: 5px;
	clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}
.v-list-item__action {
	margin: 0;
}
