






































































.summary_container {
	display: grid;
	grid-template-columns: auto auto;
	justify-content: start;
	align-items: start;
	column-gap: 1ex;
	row-gap: 3px;
}
.summary_icon {
	justify-self: center;
}
.summary_dialog {
	overflow-y: auto;
}
