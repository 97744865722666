





















.v-btn {
	margin: 0.2em;
}
