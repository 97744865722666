




























































































































































































































.v-tab, .v-btn {
	text-transform: none;
}

::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 1ex;
}
::-webkit-scrollbar-thumb {
	border-radius: 0.5ex;
	background-color: rgba(0, 0, 0, .5);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
html {
	overscroll-behavior: contain;
}
.changelog h2 {
	padding-top: 10px;
}
.changelog ul {
	padding-bottom: 10px;
}
