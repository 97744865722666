












































































.list {
	border-top: 1px;
	border-bottom: 0px;
	border-left: 0px;
	border-right: 0px;
	border-color: lightgray;
	border-style: solid;
	padding-top: 0px;
	padding-bottom: 36px;
}
.item {
	border-top: 0px;
	border-bottom: 1px;
	border-left: 0px;
	border-right: 0px;
	border-color: lightgray;
	border-style: solid;
	transition: 0;
}
.handle {
	cursor: row-resize;
}
.anim-enter, .anim-leave-to {
	opacity: 0;
}
.anim-leave-active {
	position: absolute;
	width: 100%;
}
.anim-move {
	transition: transform 0.3s;
}
.v-list-item__action {
	margin: 0;
}
.fab {
	position: fixed;
	bottom: 0;
	right: 0;
}
.fab * {
	margin: 5px;
}
.remote {
	color: gray;
}
