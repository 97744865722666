







































.v-list-item__action {
	margin: 0;
}
.v-list-item {
	min-height: 0;
}
.v-list-item__content {
	padding: 0;
}
