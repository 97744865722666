


























































































div.results .v-data-table table td, div.results .v-data-table table th, div.results .v-data-table table tr {
	height: 3em;
	padding: 3px;
}
