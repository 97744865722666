







































































.icons {
	position: absolute;
	/* match size of .v-btn */
	height: 30px;
	margin-left: 6px;
	margin-top: 6px;
	z-index: 1;
}
.icons * {
	line-height: 36px;
}
.icons > button {
	margin-top: -4px;
}
.icons.enabled * {
	color: #FFFFFF;
}
html body div.v-application .v-icon {
	vertical-align: baseline;
}
