
















































.fade-enter-active, .fade-leave-active {
	transition: opacity 0.1s ease;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}
.icons {
	position: absolute;
	/* match size of .v-btn */
	height: 30px;
	margin-left: 6px;
	margin-top: 6px;
	z-index: 1;
}
.icons * {
	line-height: 36px;
}
.icons .enabled {
	color: #FFFFFF;
}
html body div.v-application .v-icon {
	vertical-align: baseline;
}
