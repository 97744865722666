
























































































.anim-enter, .anim-leave-to {
	opacity: 0;
}
.anim-leave-active {
	position: absolute;
	width: 100%;
}
.item {
	transition: all 0.3s ease;
}
